import {
  FETCH_COMMENTS,
  RECEIVE_COMMENTS,
} from '../actions/comments';


export default function comments(state = [], action) {
  switch (action.type) {
    case FETCH_COMMENTS:
      return state;
    case RECEIVE_COMMENTS:
      return action.comments.filter(c => c.verified === true);
    default:
      return state;
  }
}
