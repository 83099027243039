import { db, firebase } from '../firebase';

const CommentAPI = {
  find: (prop, value) => {
    const comments = [];
    return new Promise((resolve, reject) => {
      db.collection('comments')
        .where(prop, '==', value)
        .orderBy('created', 'desc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const comment = doc.data();
            comment.id = comment.id;
            comments.push(comment);
          });
        })
        .then(() => {
          resolve(comments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create: (_comment, commentId) => new Promise((resolve, reject) => {
    // Prep object with custom Firestore rules
    const comment = _comment;
    db.collection('comments').doc(commentId).set({
      ...comment,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    })
      .then((result) => {
        console.log('Document successfully written!', result);
        resolve(result);
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
        reject(error);
      });
  }),
  generateFreshId: () => new Promise(async (resolve, reject) => {
    const doc = await db.collection('counters')
      .doc('comments').get()
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    const commentId = doc.data().current + 1;
    const commentIdAsString = `${commentId}`;

    await db.collection('counters').doc('comments').set({
      current: commentId,
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
    resolve(commentIdAsString);
  }),
};

export default CommentAPI;
