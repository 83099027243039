import {
  GET_COORDINATES,
  RECEIVE_COORDINATES,
  GET_ADDRESS,
  SET_ADDRESS,
  SHOW_LOCATER_COTROLS,
} from '../actions/location';


export default function location(state = {}, action) {
  switch (action.type) {
    case GET_COORDINATES:
      return { ...state, loading: true };
    case RECEIVE_COORDINATES:
      return { ...state, ...action.coords, loading: false, showLocaters: false };
    case GET_ADDRESS:
      return { ...state, loading: true };
    case SET_ADDRESS:
      return { ...state, ...action.address, loading: false, showLocaters: false };
    case SHOW_LOCATER_COTROLS:
      return { ...state, showLocaters: true };
    default:
      return state;
  }
}
