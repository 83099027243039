import Alert from 'react-s-alert';
import CommentAPI from '../lib/CommentAPI';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const RECEIVE_COMMENTS = 'RECEIVE_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';


function fetchComments(toiletId) {
  return {
    type: FETCH_COMMENTS,
    toiletId,
  };
}

function receiveComments(comments) {
  return {
    type: RECEIVE_COMMENTS,
    comments,
  };
}

function postComment(commentId) {
  return {
    type: POST_COMMENT,
    commentId,
  };
}

function postCommentSuccess(comment) {
  return {
    type: POST_COMMENT_SUCCESS,
    comment,
  };
}


export function handleFetchComments(toiletId) {
  return (dispatch) => {
    dispatch(fetchComments(toiletId));
    return CommentAPI.find('toiletId', `${toiletId}`)
      .then((comments) => {
        dispatch(receiveComments(comments));
      })
      .catch(e => console.log('There was error', e));
  };
}

export function handlePostComment(_comment) {
  return async (dispatch) => {
    const commentId = await CommentAPI.generateFreshId();
    dispatch(postComment(commentId));
    return CommentAPI.create(_comment, `${commentId}`)
      .then((comment) => {
        dispatch(postCommentSuccess(comment));
        Alert.success('Comment sucessfully added, pending verification!');
        Promise.resolve();
      })
      .catch(e => console.log('There was error', e));
  };
}
