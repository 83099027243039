import React from 'react';
import logoSmall from '../images/press/diaroogle_logo_small.jpg';
import logoMedium from '../images/press/diaroogle_logo_medium.jpg';
import screenshot from '../images/press/diaroogle_photo_screenshot.jpg';
import './AboutPage.css';

function AboutPage() {
  return (
    <div id="about-page" className="container">
      <div className="about-section">
        <h1>About Diaroogle</h1>

        <h3> What is Diaroogle ?</h3>
        <p>
          Diaroogle helps you find quality public toilets from your mobile
          phone. It&apos;s for the discerning, on-the-go defecator who is brave
          enough to use a public bathroom, but still demands a hygienic
          and private bathroom experience.  It is also a
          community-authored database of toilets
        </p>

        <h3> Is this site for real? </h3>
        <p> Unfortunately, yes.  </p>

        <h3> Why is it called Diaroogle?</h3>
        <p>
          Because some deeshbeeg took poogle.com.
          Diaroogle and poogle are both a play on google,
          however diaroogle, with it&apos;s diarrhea implications, suggests
          a greater sense of urgency
        </p>

        <h3> Didn&apos;t George Costanza come up with this idea?</h3>
        <p>
          Nope! The iToilet episode of
          <i> Curb Your Enthusiasm </i>
          aired on Nov 9th, 2009 and
          Diaroogle launched in 2008.

        </p>


        <h3> How Can I Help? </h3>
        <p>
          Add a bathroom and / or tell a friend! The success of this site
          depends on the work of others, mexican food, laxatives, and shitting.
        </p>
      </div>


      <div className="about-section">
        <h1>Contact</h1>
        <span>
          To get in touch with Evan and Kevin, the Diaroogle team,
          email info@diaroogle.com
        </span>
      </div>

      <div className="about-section">
        <h1>Press material</h1>


        <div className="press-image">
          <img src={logoSmall} alt="logo small" />
          <br />
          diaroogle_logo_small.jpg
          <span>(250x77, 8 KB)</span>
        </div>

        <div className="press-image">
          <img src={logoMedium} alt="logo medium" />
          <br />
          diaroogle_logo_medium.jpg
          <span>(585x180, 16 KB)</span>
        </div>

        <div className="press-image">
          <img src={screenshot} alt="screenshot" />
          <br />
          diaroogle_photo_screenshot
          <span>(700x470, 68 KB)</span>
        </div>
      </div>

      <em className="tagline"> Diaroogle: Poop With Impunity. </em>
    </div>
  );
}

export default AboutPage;
