import React from 'react';
import PropTypes from 'prop-types';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
// import mapStyles from './mapStyles'; // styles={mapStyles}
import './BigMap.css';

function BigMap(props) {
  const {
    google,
    toilets,
    initLat,
    initLng,
    zoom,
    onMarkerClick,
  } = props;

  return (
    <div className="big-map">
      <Map
        google={google}
        zoom={zoom}
        initialCenter={{
          lat: initLat,
          lng: initLng,
        }}
      >

        {toilets.map(toilet => (
          <Marker
            key={toilet.id}
            title={toilet.formattedAddress}
            name={toilet.name}
            position={{ lat: toilet.lat, lng: toilet.lng }}
            onClick={() => onMarkerClick(toilet.id)}
          />
        )) }
      </Map>
    </div>
  );
}

BigMap.propTypes = {
  google: PropTypes.object.isRequired,
  toilets: PropTypes.array.isRequired,
  initLat: PropTypes.number,
  initLng: PropTypes.number,
  zoom: PropTypes.number,
  onMarkerClick: PropTypes.func,
};

BigMap.defaultProps = {
  initLat: 40.7681,
  initLng: -73.9712,
  zoom: 13,
  onMarkerClick: () => console.log('hello cruel world'),
};

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
})(BigMap);
