import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import './CurrentLocation.css';

class CurrentLocation extends React.Component {
  static propTypes = {
    location: PropTypes.object,
  };

  static defaultProps = {
    location: {
      loading: false,
      lat: 0,
      lng: 0,
    },
  }

  render() {
    const { location } = this.props;
    return (
      <div className="current-location">
        <Loader
          loaded={!location.loading}
          options={{ position: 'relative' }}
        >
          <Fragment>
            <span className="coords">
              <i>Current location:&nbsp;</i>
              {location.lat}
              ,
              {location.lng}
            </span>
            <span className="address">
              {location.googleAddress ? location.googleAddress : 'New York, NY'}
            </span>
          </Fragment>
        </Loader>
      </div>

    );
  }
}

export default connect(state => ({
  location: state.location,
}))(CurrentLocation);
