import React from 'react';
import GoogleAnalytics from 'react-ga';
import PropTypes from 'prop-types';

GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

const withTracker = (WrappedComponent) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({ page });
    GoogleAnalytics.pageview(page);
  };

  const HOC = (props) => {
    HOC.propTypes = {
      location: PropTypes.object.isRequired,
    };

    const page = props.location.pathname;
    trackPage(page);

    return (
      <WrappedComponent {...props} />
    );
  };

  return HOC;
};


export default withTracker;
