import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <Fragment>
      <div className="ragged" />
      <footer>
        <Link to="/about"> About </Link>
        <Link to="/help"> Help </Link>
        <Link to="/press-and-praise"> Press &amp; Press </Link>
      </footer>
    </Fragment>
  );
}

export default Footer;
