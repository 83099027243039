import React from 'react';
import PropTypes from 'prop-types';
import DateTime from '../../ui/DateTime';
import './Comment.css';

function Comment(props) {
  const { comment } = props;
  return (
    <div className="comment">
      <div className="comment-header">
        On&nbsp;
        <span>
          <DateTime timestamp={comment.created} />
        </span>
        &nbsp;
        {comment.name}
        &nbsp;
        said:
      </div>
      <div className="comment-body">
        {comment.comment}
      </div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
};


export default Comment;
