import { combineReducers } from 'redux';

import todos from './todos';
import loading from './loading';
import location from './location';
import toilets from './toilets';
import comments from './comments';

export default combineReducers({
  todos,
  location,
  loading,
  toilets,
  comments,
});
