import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleGetLocation } from '../../actions/location';
import './LocationForm.css';

class LocationForm extends React.Component {
  state = {
    address: '',
  }

  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    dispatch: () => {},
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { address } = this.state;
    const { dispatch, history } = this.props;
    dispatch(handleGetLocation(address, () => { history.push('/toilets'); }));
  }


  render() {
    return (
      <div className="location-form">
        <form onSubmit={this.onSubmit}>
          <input
            type="text"
            placeholder="What's your current location?"
            name="address"
            onChange={e => this.setState({ address: e.target.value })}
          />
        </form>
        <span>
          An address, cross streets, landmark, or any location
        </span>
      </div>
    );
  }
}


export { LocationForm };

export default connect(state => ({
  location: state.location,
}))(withRouter(LocationForm));
