import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import defaultToiletImage from '../../images/default_toilet_85.gif';

function ToiletPhoto(props) {
  const { toilet } = props;
  let imageURL = `https://firebasestorage.googleapis.com/v0/b/diaroogle-xl.appspot.com/o/toilets%2F${toilet.id}%2Fsmall.jpg?alt=media`;
  if (toilet.fromV1) {
    imageURL = `https://diaroogle.s3.amazonaws.com/toilets/${toilet.id}/small/${toilet.originalImage}`;
  }
  const url = `/toilets/${toilet.id}`;
  const onError = (e) => {
    e.persist();
    e.target.src = defaultToiletImage;
  };
  return (
    <Fragment>
      <a href={url}>
        {toilet.originalImage && (
          <img
            src={imageURL}
            onError={onError}
            alt={`Bathroom for ${toilet.name}`}
          />)
        }
        {!toilet.originalImage && (
          <img
            src={defaultToiletImage}
            alt={`Bathroom for ${toilet.name}`}
          />)
        }
      </a>
    </Fragment>
  );
}

ToiletPhoto.propTypes = {
  toilet: PropTypes.object.isRequired,
};

export default ToiletPhoto;
