import {
  RECEIVE_DATA
} from '../actions/shared'

import {
  GET_COORDINATES
} from '../actions/location'

export default function loading (state = true, action) {
  switch(action.type) {
    case RECEIVE_DATA :
      return false
    case GET_COORDINATES:
      return true
    default :
      return state
  }
}
