import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './AutoLocate.css';
import { handleGetCoordinates } from '../../actions/location';

export class AutoLocate extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    dispatch: () => {},
  };

  getCoordinate = () => {
    const { dispatch, history } = this.props;
    dispatch(handleGetCoordinates());
    history.push('/toilets');
  }

  render() {
    return (
      <div className="auto-locate">
        <button
          type="button"
          onClick={this.getCoordinate}
        >
          <i className="fa fa-map-marker-alt" aria-hidden="true" />
          Set Location
        </button>
      </div>
    );
  }
}

export default connect(state => ({
  loading: state.loading,
}))(withRouter(AutoLocate));
