import React from 'react';
import './PressAndPraisePage.css';

function PressAndPraisePage() {
  return (
    <div id="press-page" className="container">
      <h1>What others are saying about Diaroogle...</h1>

      <p>
        &ldquo;The aptly, if somewhat disgustingly, named Diaroogle.com is a
        nifty search engine that gives the knock-kneed and desperate a
        reliable list of loos.&rdquo; -
        <a href="http://www.nypost.com/seven/10122008/news/regionalnews/s__ts_and_googles_133232.htm">nypost.com</a>
      </p>

      <p>
        &ldquo;Why didn’t anyone ever think of this? This could grow into the
        world’s most useful database.&rdquo; - killerstartups.com
      </p>

      <p>
        &ldquo;This is the single greatest URL I have ever seen in my life...
        and the site is actually really helpful.&rdquo; -whatwereinto.com
      </p>

      <p>
        &ldquo;Is the founder of this site George Costanza?&rdquo; - Digg (commentor)
      </p>

      <p>
        &ldquo;The Internet has come through for people who just want a clean place
        to go with new tools like Diaroogle -
        <a href="http://m.cnn.com/cnn/archive/archive/detail/177623/full;jsessionid=738B94671FCBA73C6784C805BF5D8FA2">cnn.com</a>
      </p>

      <p>
        Mashup of the Day - Aug 25th, 2008 - http://mashupawards.com/diaroogle/
      </p>

      <p>
        &ldquo;Human-powered search engine Diaroogle is up to the task!&rdquo; -cnet.com
      </p>

      <p>
        &ldquo;This is a god-send. &rdquo; -Lifehacker (commentor)
      </p>

      <p>
        Search Engine of the Day: August 20th, 2008 - zdnet.com
      </p>

      <p>
        &ldquo;Beyond excelling at ingress strategies, Diaroogle shows
        promise of becoming a guide to both host establishments, and
        even general NY lifestyle&rdquo; - thrillest.com
      </p>

      <p>
        &ldquo;if you live in NYC, or the next time you&apos;re visiting, put
        Diaroogle in you mobile bookmarks–you never know when that McDonald’s
        breakfast will want to make a quick exit.&rdquo; -www.coedmagazine.com
      </p>

      <p>
        &ldquo; ...much easier to use on your phone that Mizpee. and when ya gotta
        go (like I did today) every second counts.&rdquo; - trendhunter.com (commentor)
      </p>

      <p>
        &ldquo;I am loving this site I found via * via BuzzFeed ! And it&apos;s not just
        because of its great name -
        it actually seems to works and you know if you&apos;re
        needing this service while on the go, you really really need it to
        work.&rdquo; - babbledog.com
      </p>

      <ul>
        <h1>Some random tweets on twitter.com:</h1>
        <li>
          &ldquo;diaroogle rools. I need that at work.
          Our bathrooms are like booby trapped caves right out
          of Indy jones. Minus harrison.&rdquo;
        </li>
        <li>&ldquo;A must for iphones in NYC&rdquo;</li>
        <li>
          &ldquo;I caught myself reading the toilet directions &amp; laughing
          30 min, had to force myself to close it.&rdquo;
        </li>
        <li>&ldquo;Thank you Diaroogle... you&apos;ve made my life easier. &rdquo;</li>
        <li>&ldquo;this has *got* to be a joke&rdquo;</li>
        <li>
          &ldquo;I ranted about needing something like this about 5 million
          times when I lived in NYC&rdquo;
        </li>
        <li>&ldquo;george costanza would be proud&rdquo;</li>
        <li>
          &ldquo;Now this is a service! http://www.diaroogle.com/
          Find public toilets in NYC from a cellphone? I&apos;m behind it all the way!&rdquo;
        </li>
        <li>
          &ldquo;haven&apos;t decided if this is the most helpful or
          unnecessary phone app ever&rdquo;
        </li>
      </ul>

      <em className="tagline">
        If you&apos;re interested in advertising on this site,
        please send an email to info@diaroogle.com
      </em>

    </div>
  );
}

export default PressAndPraisePage;
