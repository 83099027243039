import {
  GET_TOILETS,
  RECEIVE_TOILETS,
} from '../actions/toilets';


export default function toilets(state = [], action) {
  switch (action.type) {
    case GET_TOILETS:
      return state;
    case RECEIVE_TOILETS:
      return action.toilets;
    default:
      return state;
  }
}
