import React, { Fragment } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';
import LocationAPI from '../../lib/LocationAPI';
import './Form.css';

class Form extends React.Component {
  state = {
    name: '',
    address: '',
    description: '',
    hoursOfOperation: '',
    accessibility: 1,
    privacy: 1,
    cleanliness: 1,
    motherApproved: false,
    changingTable: false,
    handicappedAccessible: false,
    isLoadingAddress: false,
  }

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  onSubmit = async (e) => {
    e.preventDefault();
    // validate
    const { handleAddToilet } = this.props;

    if (this.isFormValid()) {
      // do something with files
      const file = this.fileInput.current.files[0];
      const toilet = {
        ...this.state,
      };
      handleAddToilet(toilet, file);
    }
  }

  isFormValid = () => {
    const errors = {};
    const {
      name,
      address,
      description,
    } = this.state;
    if (this.fileInput.current === null) {
      errors.image = 'Please select an image';
    }
    if (name === '') {
      errors.name = 'Name cannot be blank';
    }

    if (address === '') {
      errors.address = 'Address cannot be blank';
    }

    if (description === '') {
      errors.description = 'Description cannot be blank';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      scrollToElement('form', {
        offset: 0,
        ease: 'out-elastic',
        duration: 800,
      });
      return false;
    }
    return true;
  }

  handleChange = (e) => {
    const value = e.target.type === 'checkbox'
      ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: value,
      // clear out existing erors while user is typing
    });
  }

  render() {
    const {
      name,
      address,
      description,
      hoursOfOperation,
      accessibility,
      privacy,
      cleanliness,
      motherApproved,
      changingTable,
      handicappedAccessible,
      errors,
      isLoadingAddress,
    } = this.state;
    return (
      <div className="toilet-form">
        {errors && Object.keys(errors).length > 0 && (
          <div className="form-errors">
            <ul>
              {Object.keys(errors).map(key => (
                <li key={key}>
                  {errors[key]}
                </li>
              ))}
            </ul>

          </div>
        )}
        <form onSubmit={this.onSubmit}>
          <div className="file-group">
            <label>
              <i className="fas fa-camera" />
              &nbsp;
                Add a photo
              <input
                type="file"
                name="fileInput"
                ref={this.fileInput}
                accept="image/*"
              />
            </label>
          </div>
          <div className="text-group">
            <label>
              Building / Establishment Name
              <input
                type="text"
                name="name"
                placeholder="Starbucks"
                value={name}
                onChange={this.handleChange}
              />
              <span>Example: Starbucks</span>
            </label>
          </div>

          <div className="text-group">
            <label>
              Address
              <button
                type="button"
                className="locate-me"
                onClick={() => {
                  this.setState({ isLoadingAddress: true });
                  LocationAPI.currentAddress().then(googleAddress => (
                    this.setState({ address: googleAddress })
                  )).finally(() => {
                    this.setState({ isLoadingAddress: false });
                  });
                }}
              >
                <i className="fa fa-map-marker-alt" aria-hidden="true" />
                &nbsp;
                { !isLoadingAddress
                  ? <Fragment> Set to current location</Fragment>
                  : (
                    <Fragment>
                      Loading address...
                      <i className="fa fa-spinner fa-spin" />
                    </Fragment>
                  )
                }
              </button>
              <input
                type="text"
                name="address"
                placeholder="Full Address, cross streets, or landmark"
                value={address}
                onChange={this.handleChange}
              />
              <span>Example: 413 East 9th Street, New York, NY</span>
            </label>
          </div>

          <div className="text-group">
            <label>
              Description
              <textarea
                name="description"
                placeholder="Details about the bathroom, tips on how to get in unnoticed, etc"
                value={description}
                onChange={this.handleChange}
              />
            </label>
          </div>

          <div className="text-group">
            <label>
              Hours of Operation
              <input
                type="text"
                placeholder="4pm to Midnight"
                name="hoursOfOperation"
                value={hoursOfOperation}
                onChange={this.handleChange}
              />
            </label>
          </div>

          {/* ACCESIBILITY */}
          <div className="radio-group">
            <h3> Accessibility </h3>
            <div>
              <label>
                <input
                  type="radio"
                  name="accessibility"
                  value={1}
                  checked={parseInt(accessibility, 10) === 1}
                  onChange={this.handleChange}
                />
              Fort Knox
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="accessibility"
                  value={3}
                  checked={parseInt(accessibility, 10) >= 2 && parseInt(accessibility, 10) <= 4}
                  onChange={this.handleChange}
                />
               Might get the side eye
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="accessibility"
                  value={5}
                  checked={parseInt(accessibility, 10) === 5}
                  onChange={this.handleChange}
                />
              Walk right in
              </label>
            </div>
          </div>
          {/* PRIVACY */}
          <div className="radio-group">
            <h3> Privacy</h3>
            <div>
              <label>
                <input
                  type="radio"
                  name="privacy"
                  value="1"
                  checked={parseInt(privacy, 10) === 1}
                  onChange={this.handleChange}
                />
              I feel like I&apos;m being watched
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="privacy"
                  value="3"
                  checked={parseInt(privacy, 10) >= 2 && parseInt(privacy, 10) <= 4}
                  onChange={this.handleChange}
                />
               Reasonable
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="privacy"
                  value="5"
                  checked={parseInt(privacy, 10) === 5}
                  onChange={this.handleChange}
                />
              Blissfully unaware of the outside world
              </label>
            </div>
          </div>

          {/* CLEANINESS */}
          <div className="radio-group">
            <h3> Cleanliness </h3>
            <div>
              <label>
                <input
                  type="radio"
                  name="cleanliness"
                  value="1"
                  checked={parseInt(cleanliness, 10) === 1}
                  onChange={this.handleChange}
                />
              A hellhole
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="cleanliness"
                  value="3"
                  checked={parseInt(cleanliness, 10) >= 2 && parseInt(cleanliness, 10) <= 4}
                  onChange={this.handleChange}
                />
               Fit for humans
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="cleanliness"
                  value="5"
                  checked={parseInt(cleanliness, 10) === 5}
                  onChange={this.handleChange}
                />
                You could eat off the toilet
              </label>
            </div>
          </div>

          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="motherApproved"
                checked={motherApproved}
                onChange={this.handleChange}
              />
              Mother Approved
            </label>
            <span> Would you let your mother use this bathroom?</span>
          </div>

          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="changingTable"
                checked={changingTable}
                onChange={this.handleChange}
              />
            Good for parents with little kids
            </label>
            <span>
              Diaper changing station / kid friendly / supports pair-pooping or team-peeing
            </span>
          </div>

          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="handicappedAccessible"
                value="checked"
                checked={handicappedAccessible}
                onChange={this.handleChange}
              />
              Handicapped Accessible
            </label>
            <span>Can I bring my wheelchair?</span>
          </div>
          <div className="recaptcha">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={val => console.log(val)}
            />
          </div>
          <div className="submit-group">
            <label>
              <input type="submit" />
            </label>
          </div>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  handleAddToilet: PropTypes.func,
};

Form.defaultProps = {
  handleAddToilet: () => {},
};

export default Form;
