import React from 'react';
import Alert from 'react-s-alert';
import Loader from 'react-loader';
import { withRouter } from 'react-router-dom';
import Form from '../../components/toilet/Form';
import ToiletAPI from '../../lib/ToiletAPI';
import LocationAPI from '../../lib/LocationAPI';

class NewToiletPage extends React.Component {
  state = {
    isLoading: false,
  }

  handleAddToilet = async (_toilet, file) => {
    this.setState({ isLoading: true });
    const toiletId = await ToiletAPI.generateFreshId();
    const originalImage = await ToiletAPI.upload(file, toiletId);
    const geo = await LocationAPI.address2geo(_toilet.address);
    const toilet = {
      ..._toilet,
      id: toiletId,
      originalImage,
      ...geo,
    };
    ToiletAPI.create(toilet, toiletId).then(() => {
      this.setState({ isLoading: false });
      const { history } = this.props;
      Alert.success('Toilet sucessfully added, pending verification!');
      history.push('/');
    });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="container">
        <Loader loaded={!isLoading}>
          <h1 className="page-title"> Submit A Toilet </h1>
          <Form handleAddToilet={this.handleAddToilet} />
        </Loader>
      </div>
    );
  }
}

export default withRouter(NewToiletPage);
