import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToiletResult from '../../components/toilet/ToiletResult';
import {
  handleGetToilets,
} from '../../actions/toilets';
import {
  handleGetCoordinates,
} from '../../actions/location';

export class BrowseToiletsPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    dispatch: PropTypes.func,
    toilets: PropTypes.array,
  };

  static defaultProps = {
    location: {},
    dispatch: () => {},
    toilets: [],
  };

  componentWillMount() {
    const { location, dispatch } = this.props;
    dispatch(handleGetToilets(location.lat, location.lng));
    if (!location.googleAddress) {
      dispatch(handleGetCoordinates());
    }
  }

  componentDidUpdate(prevProps) {
    const { location, dispatch } = this.props;
    if (location.googleAddress !== prevProps.location.googleAddress) {
      dispatch(handleGetToilets(location.lat, location.lng));
    }
  }

  render() {
    const { toilets } = this.props;
    return (
      <div className="container">
        <h1 className="page-title"> LOCAL TOILETS </h1>
        {toilets.length > 0 && toilets.map(toilet => (
          <ToiletResult key={toilet.id} toilet={toilet} />
        ))}
      </div>
    );
  }
}


export default connect(state => ({
  toilets: state.toilets,
  location: state.location,
}))(BrowseToiletsPage);
