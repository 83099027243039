import React from 'reactn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoLocate from './AutoLocate';
import LocationForm from './LocationForm';
import CurrentLocation from './CurrentLocation';
import { handleShowLocaterControls } from '../../actions/location';
import './LocaterBox.css';

class LocaterBox extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    const { location, dispatch } = this.props;
    return (
      <div className="container">
        <div className="locater-box">
          {location && location.showLocaters && (
            [<LocationForm key="1" />,
              <div className="or" key="2">
                <div className="bar">|</div>
                <div>or</div>
                <div className="bar">|</div>
              </div>,
              <AutoLocate key="3" location={this.global.location} />]
          )}
          <CurrentLocation />
          {location && !location.showLocaters && (
          <button
            type="button"
            className="toggle-button"
            onClick={() => dispatch(handleShowLocaterControls())}
          >
            <span>Update Location</span>
          </button>
          )
        }
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  location: state.location,
}))(LocaterBox);
