import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class Form extends React.Component {
  state = {
    name: '',
    email: '',
    comment: '',
  }

  static propTypes = {
    handlePostComment: PropTypes.func.isRequired,
    toilet: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  onSubmit = (e) => {
    const { handlePostComment, toilet, dispatch } = this.props;
    const { comment, email, name } = this.state;
    e.preventDefault();
    const _comment = {
      name,
      email,
      comment,
      toiletId: toilet.id,
    };
    dispatch(handlePostComment(_comment));
    this.setState({
      comment: '',
      email: '',
      name: '',
    });
  }

  handleChange = (e) => {
    const value = e.target.type === 'checkbox'
      ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: value,
      // clear out existing erors while user is typing
    });
  }

  render() {
    const { name, email, comment } = this.state;
    return (
      <div>
        <h2 className="page-title"> Add a Comment </h2>
        <form onSubmit={this.onSubmit}>
          <div className="text-group">
            <label>
              Name
              <input
                type="text"
                name="name"
                placeholder="Your name"
                value={name}
                onChange={this.handleChange}
              />
              <span>Example: Starbucks</span>
            </label>
          </div>

          <div className="text-group">
            <label>
              Email (Optional)
              <input
                type="text"
                name="email"
                placeholder="email address"
                value={email}
                onChange={this.handleChange}
              />
              <span>To be notified when someone replies</span>
            </label>
          </div>

          <div className="text-group">
            <label>
              Comment
              <textarea
                name="comment"
                placeholder="Comment"
                value={comment}
                onChange={this.handleChange}
              />
            </label>
          </div>

          <div className="submit-group">
            <label>
              <input type="submit" />
            </label>
          </div>
        </form>
      </div>
    );
  }
}

export default connect()(Form);
