import fb, { db, firebase } from '../firebase';

const ToiletAPI = {
  upload: (file, toiletId) => (
    new Promise(async (resolve, reject) => {
      const storageRef = fb.storage().ref();
      const fileRef = storageRef.child(`toilets/${toiletId}/${file.name}`);

      const snapshot = await fileRef.put(file).catch((e) => {
        console.log(e);
        reject();
      });
      const downloadURL = await snapshot.ref.getDownloadURL();
      resolve(downloadURL);
    })
  ),
  find: () => {
    const toilets = [];
    return new Promise((resolve, reject) => {
      db.collection('toilets')
        .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const toilet = doc.data();
            toilet.id = doc.id;
            toilets.push(toilet);
          });
        })
        .then(() => {
          resolve(toilets);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  findOne: (id) => {
    let result;
    return new Promise((resolve, reject) => {
      const docRef = db.collection('toilets').doc(id);

      docRef.get().then((doc) => {
        if (doc.exists) {
          result = doc.data();
          result.id = doc.id;
          console.log('Document data:', doc.data());
          resolve(result);
        } else {
          console.log('No such document!');
          reject();
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
        reject();
      });
    });
  },
  create: (_toilet, toiletId) => new Promise((resolve, reject) => {
    // Prep object with custom Firestore rules
    const toilet = _toilet;
    db.collection('toilets').doc(toiletId).set({
      ...toilet,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    })
      .then((result) => {
        console.log('Document successfully written!', result);
        console.log(result);
        resolve(result);
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
        reject(error);
      });
  }),

  generateFreshId: () => new Promise(async (resolve, reject) => {
    const doc = await db.collection('counters')
      .doc('toilets').get()
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    const toiletId = doc.data().current + 1;
    const toiletIdAsString = `${toiletId}`;

    await db.collection('counters').doc('toilets').set({
      current: toiletId,
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
    resolve(toiletIdAsString);
  }),
};

export default ToiletAPI;
