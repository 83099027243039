import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ToiletPhoto from './ToiletPhoto';
import ToiletRating from './ToiletRating';
import './Header.css';

function Header(props) {
  const { toilet } = props;
  const url = `/toilets/${toilet.id}`;
  return (
    <div className="toilet-header">
      <div className="info">
        <div className="name">
          <h3><Link to={url}>{toilet.name}</Link></h3>
        </div>
        <div className="address-line1">
          {toilet.address}
        </div>
        {toilet.crossStreet && (
          <div className="cross-street">
            @
            &nbsp;
            {toilet.crossStreet}
          </div>)
        }
        <ToiletRating toilet={toilet} />
        <div className="address-line2">
          {`${toilet.city}, ${toilet.state}, ${toilet.zip}`}
        </div>
      </div>
      <div className="photo">
        <ToiletPhoto toilet={toilet} />
      </div>
    </div>
  );
}

Header.propTypes = {
  toilet: PropTypes.object.isRequired,
};

export default Header;
