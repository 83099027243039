import SearchAPI from '../lib/SearchAPI';

export const GET_TOILETS = 'GET_TOILETS';
export const RECEIVE_TOILETS = 'RECEIVE_TOILETS';


export function getToilets(lat, lng) {
  return {
    type: GET_TOILETS,
    lat,
    lng,
  };
}

function receiveToilets(toilets) {
  return {
    type: RECEIVE_TOILETS,
    toilets,
  };
}


export function handleGetToilets(lat, lng) {
  return (dispatch) => {
    dispatch(getToilets(lat, lng));
    return SearchAPI.search(lat, lng)
      .then((toilets) => {
        dispatch(receiveToilets(toilets.hits));
      })
      .catch(e => console.log('There was error', e));
  };
}
