import React from 'react';
import './NotFoundPage.css';

function NotFoundPage() {
  return (
    <div className="container">
      <div className="error-404">
        <h1> 404 </h1>
        <p> YIKERS ISLAND! </p>
        <p> It looks that like page no longer exists ... </p>
      </div>
    </div>
  );
}

export default NotFoundPage;
