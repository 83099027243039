import LocationAPI from '../lib/LocationAPI';

export const GET_COORDINATES = 'GET_COORDINATES';
export const RECEIVE_COORDINATES = 'RECEIVE_COORDINATES';
export const GET_ADDRESS = 'GET_ADDRESS';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SHOW_LOCATER_COTROLS = 'SHOW_LOCATER_CONTORLS';

function getCoordinates(coords) {
  return {
    type: GET_COORDINATES,
    coords,
  };
}

function setCoordinates(coords) {
  return {
    type: RECEIVE_COORDINATES,
    coords,
  };
}

function getAddress() {
  return {
    type: GET_ADDRESS,
  };
}

function setAddress(address) {
  return {
    type: SET_ADDRESS,
    address,
  };
}

function showLocaterControls() {
  return {
    type: SHOW_LOCATER_COTROLS,
  };
}

export function handleGetCoordinates() {
  return async (dispatch) => {
    dispatch(getCoordinates());
    let coords;
    try {
      coords = await LocationAPI.currentCoordinates();
    } catch (err) {
      coords = await LocationAPI.ip2geo();
    } finally {
      dispatch(setCoordinates(coords));
      const address = await LocationAPI.geo2address(coords.lat, coords.lng);
      dispatch(setAddress(address));
    }
  };
}

export function handleGetLocation(address, cb = () => {}) {
  return async (dispatch) => {
    dispatch(getAddress());
    const location = await LocationAPI.address2geo(address);
    dispatch(setAddress(location));
    cb();
  };
}

export function handleShowLocaterControls() {
  return async (dispatch) => {
    dispatch(showLocaterControls());
  };
}
