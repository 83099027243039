import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleFetchComments } from '../../actions/comments';
import Comment from './Comment';
import './List.css';

export class List extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    toilet: PropTypes.object,
    comments: PropTypes.array,
  }

  static defaultProps = {
    dispatch: () => {},
    toilet: {},
    comments: [],
  }

  componentWillMount() {
    const { toilet, dispatch } = this.props;
    dispatch(handleFetchComments(toilet.id));
  }

  render() {
    const { comments } = this.props;
    return (
      <div className="comments">
        {comments && comments.length > 0 && comments.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </div>
    );
  }
}

export default connect(state => ({
  comments: state.comments,
}))(List);
