import React from 'react';
import Moment from 'react-moment';

function DateTime(props) {
  const { timestamp } = props;
  let date = timestamp;
  if (timestamp.seconds) {
    date = new Date().setTime(1000 * timestamp.seconds);
  }
  return (
    <Moment format="M/DD/YY HH:mm">
      {date}
    </Moment>
  );
}

export default DateTime;
