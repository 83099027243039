import React from 'react';
import './HelpPage.css';

function HelpPage() {
  return (
    <div id="help-page" className="container">
      <div className="about-section">
        <h1>Help</h1>
        <h3> How do I find a toilet from my mobile phone? </h3>

        <p>
          Simply point your mobile phone at diaroogle.com, and you
          will see a mobile-optimized version of this site. From there you can
          enter an address, a cross street, a zip code, neighborhood, park or
          local landmark. Diaroogle will send you back the top toilets in
          your area.
        </p>

        <p>
          The rest of this site is pretty straight forward, but if you run
          into a problem or have a question, feel free to email
          <em> info@diaroogle.com </em>
          and we&apos;ll be happy to answer it.
        </p>
      </div>


      <div className="about-section">
        <h1>Contact</h1>
        <span>
          To get in touch with Evan and Kevin, the Diaroogle team,
          email info@diaroogle.com
        </span>
      </div>


      <em className="tagline"> Diaroogle: When Shitting Your Pants Is Not An Option </em>

    </div>
  );
}

export default HelpPage;
