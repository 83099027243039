import request from 'superagent';
import publicIp from 'public-ip';

const baseURL = 'https://maps.googleapis.com/maps/api/geocode/json?';
const apiKey = `&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

const formatResult = (result = {}) => {
  const {
    address_components: ac,
    geometry, formatted_address: formattedAddress,
  } = result;
  return {
    lat: geometry ? geometry.location.lat : 0.0,
    lng: geometry ? geometry.location.lng : 0.0,
    googleAddress: formattedAddress || '',
    city: ac && ac[3] ? ac[3].long_name : '',
    state: ac && ac[5] ? ac[5].short_name : '',
    country: ac && ac[6] ? ac[6].long_name : '',
    zip: ac && ac[7] ? ac[7].short_name : '',
  };
};
// https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&
const LocationAPI = {
  address2geo: address => (
    new Promise((resolve, reject) => {
      const url = `${baseURL}address=${address}${apiKey}`;

      request
        .get(url)
        .end((err, res) => {
          if (err) {
            reject(err);
          }
          const result = formatResult(res.body.results[0]);
          resolve(result);
        });
    })
  ),
  geo2address: (lat, lng) => (
    new Promise((resolve, reject) => {
      const url = `${baseURL}latlng=${lat},${lng}${apiKey}`;

      request
        .get(url)
        .end((err, res) => {
          if (err) {
            reject(err);
          }
          const result = formatResult(res.body.results[0]);
          resolve(result);
        });
    })
  ),
  currentIPaddress: () => (
    publicIp.v4().then((ip) => {
      console.log(ip);
      return ip;
    })
  ),
  ip2geo: () => (
    new Promise((resolve) => {
      publicIp.v4().then((ip) => {
        const url = `https://api.ipstack.com/${ip}?access_key=${process.env.REACT_APP_IPSTACK}`;
        // get iP
        fetch(url)
          .then((res) => {
            res.json().then((data) => {
              console.log(data);
              const geo = {
                lat: data.latitude,
                lng: data.longitude,
              };
              resolve(geo);
            });
          });
      });
    })
  ),
  currentCoordinates: () => (
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const coordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        resolve(coordinates);
      },
      (error) => {
        reject(error);
      });
    })
  ),
  currentAddress: async () => {
    const coords = await LocationAPI.currentCoordinates();
    const address = await LocationAPI.geo2address(coords.lat, coords.lng);
    return address.googleAddress;
  },
};

export default LocationAPI;
