import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BigMap from '../components/maps/BigMap';
import ToiletAPI from '../lib/ToiletAPI';
import flourish from '../images/flourish.png';
import './LandingPage.css';

class LandingPage extends React.Component {
  state = {
    toilets: [],
  };

  componentDidMount() {
    ToiletAPI.find().then((toilets) => {
      this.setState({ toilets });
    });
  }

  onMarkerClick = (id) => {
    const { history } = this.props;
    history.push(`/toilets/${id}`);
  };

  render() {
    const { toilets } = this.state;
    return (
      <div id="landing-page" className="container">
        <h3>
          Diaroogle helps you find quality public toilets from your
          mobile phone.
          <img src={flourish} alt="a pretty little thing" />
        </h3>
        <p>
          It&apos;s for the discerning, on-the-go defecator who is brave enough
          to use a public bathroom, but still demands a hygienic and private
          bathroom experience. It is also a community authored
          database of New York toilets.
        </p>
        <div className="salutations">
          <em>Good luck to you,</em>
          <br />
          <span><a href="/about">The Diaroogle Team</a></span>
        </div>
        <BigMap
          toilets={toilets}
          onMarkerClick={this.onMarkerClick}
        />
      </div>
    );
  }
}

LandingPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(LandingPage);
