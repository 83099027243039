import React from 'react';
import PropTypes from 'prop-types';
import List from '../comment/List';
import Form from '../comment/Form';
import ToiletRating from './ToiletRating';
import ToiletPhoto from './ToiletPhoto';
import BigMap from '../maps/BigMap';
import { handlePostComment } from '../../actions/comments';
import './Toilet.css';

function Toilet(props) {
  const { toilet } = props;
  const toilets = [];
  toilets.push(toilet);
  return (
    <div className="container">
      <div className="toilet">
        <div className="info">
          <div className="name">
            <h3>{toilet.name}</h3>
          </div>
          <div className="address-line1">
            {toilet.address}
          </div>
          <ToiletRating toilet={toilet} />
          <div className="address-line2">
            {`${toilet.city}, ${toilet.state}, ${toilet.zip}`}
          </div>
          <div className="cross-street">
            {toilet.crossStreet}
          </div>

        </div>
        <div className="photo">
          <ToiletPhoto toilet={toilet} />
        </div>

        <div className="description">
          {toilet.description}
        </div>

        {toilet.hoursOfOperation && (
          <div className="hours">
            <span>Hours of Operation: </span>
            {toilet.hoursOfOperation}
          </div>)
        }

        <h3> Ratings </h3>
        <div className="ratings">
          <div className="accessibility">
            Accessibility:&nbsp;
            {toilet.accessibility}
            / 5
          </div>
          <div className="accessibility">
            Privacy:&nbsp;
            {toilet.privacy}
            / 5
          </div>
          <div className="accessibility">
            Cleanliness:&nbsp;
            {toilet.cleanliness}
            / 5
          </div>
        </div>

        <h3> Amenities </h3>
        <div className="amenities">
          <div className="mother-approved">
            Mother Approved:&nbsp;
            {toilet.motherApproved ? 'Yes' : 'No'}
          </div>
          <div className="changing-table">
            Changing Table:&nbsp;
            {toilet.changingTable ? 'Yes' : 'No'}
          </div>
          <div className="handicapped-accessible">
            Handicapped Accessible:&nbsp;
            {toilet.handicappedAccessible ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <BigMap
        toilets={toilets}
        initLat={toilet.lat}
        initLng={toilet.lng}
        zoom={18}
      />
      <List toilet={toilet} />
      <Form toilet={toilet} handlePostComment={handlePostComment} />
    </div>
  );
}

Toilet.propTypes = {
  toilet: PropTypes.object.isRequired,
};

export default Toilet;
