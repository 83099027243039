import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../images/toilet.png';
import rip from '../../images/rip.png';

function Header() {
  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="company">
        <Link to="/">
          <h3>Diaroogle.com</h3>
          <h4>The Premier Toilet Search Engine</h4>
        </Link>
      </div>
      <div className="spacer" />
      <div>
        <Link to="/toilets">
          <h3>
            Browse
          </h3>
          <h4>Current Location</h4>
        </Link>
      </div>
      <div>
        <Link to="/toilets/new">
          <h3>Submit</h3>
          <h4>Add A New Toilet</h4>
        </Link>
      </div>
      <div className="rip">
        <img src={rip} alt="ripped corner" />
      </div>
      {/* <div>
        <header>
          <h1><Link to="/">Diaroogle.com</Link></h1>
          <h2>The Premier Toilet Search Engine</h2>
          <h3>Find toilets using your mobile phone, Blackberry or iPhone.</h3>
          <div className="main-tools">
            <div className="browse-all">
              <Link to="/toilets"> Browse All</Link>
            </div>
            <div className="submit-a-toilet">
              <Link to="/toilets/new">Submit a Toilet</Link>
            </div>
          </div>
        </header>
      </div> */}
    </header>
  );
}

export default Header;
