import React from 'react';
import PropTypes from 'prop-types';
import ToiletAPI from '../../lib/ToiletAPI';
import Toilet from '../../components/toilet/Toilet';

class ShowToiletPage extends React.Component {
  state = {
    toilet: undefined,
  }

  componentWillMount() {
    const { match: { params: { id } } } = this.props;
    ToiletAPI.findOne(id).then(toilet => (
      this.setState({ toilet })
    ));
  }

  render() {
    const { toilet } = this.state;
    return (
      <div className="container">
        {toilet
          && <Toilet toilet={toilet} />
        }
      </div>
    );
  }
}

ShowToiletPage.propTypes = {
  match: PropTypes.object.isRequired,
};
export default ShowToiletPage;
