import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import HelpPage from './pages/HelpPage';
import PressAndPraisePage from './pages/PressAndPraisePage';
import BrowseToiletsPage from './pages/toilets/BrowseToiletsPage';
import NewToiletPage from './pages/toilets/NewToiletPage';
import ShowToiletPage from './pages/toilets/ShowToiletPage';
import NotFoundPage from './pages/NotFoundPage';


const Routes = () => (
  <div>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/help" component={HelpPage} />
      <Route path="/press-and-praise" component={PressAndPraisePage} />
      <Route exact path="/toilets" component={BrowseToiletsPage} />
      <Route exact path="/toilets/new" component={NewToiletPage} />
      <Route exact path="/toilets/:id" component={ShowToiletPage} />
      {/* Redirects for old site */}
      <Redirect from="/pages/help" to="/help" />
      <Redirect from="/pages/press_and_praise" to="/press-and-praise" />
      <Route component={NotFoundPage} />
    </Switch>
  </div>
);

export default Routes;
