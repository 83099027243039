import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import './ToiletRating.css';

function ToiletRating(props) {
  const { toilet } = props;
  const rating = (
    parseInt(toilet.privacy, 10)
    + parseInt(toilet.accessibility, 10)
    + parseInt(toilet.cleanliness, 10)) / 3;
  return (
    <div className="toilet-rating">
      <em>{rating.toFixed(2)}</em>
      { toilet && (
        <Rating
          start={0}
          stop={5}
          initialRating={rating}
          emptySymbol="fa fa-star-o fa-2x star-empty"
          fullSymbol="fa fa-star fa-2x star-full"
          readonly
        />
      )}
    </div>
  );
}

ToiletRating.propTypes = {
  toilet: PropTypes.object.isRequired,
};

export default ToiletRating;
