import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Header from './components/layout/Header';
import LocaterBox from './components/location/LocaterBox';
import Routes from './Routes';
import Footer from './components/layout/Footer';
import withTracker from './components/analytics/withTracker';
import LocationAPI from './lib/LocationAPI';
import './App.css';

class App extends Component {
  render() {
    LocationAPI.currentIPaddress();
    return (
      <div className="app-container">
        <Header />
        <LocaterBox />
        <div className="liner" />
        <Routes />
        <Footer />
        <Alert effect="slide" stack={{ limit: 3, position: 'top-right' }} />
      </div>

    );
  }
}

export default withRouter(withTracker(App));
