import React from 'react';
import PropTypes from 'prop-types';
import './ToiletResult.css';
import Header from './Header';

function ToiletResult(props) {
  const { toilet } = props;

  return (
    <div className="toilet-result clearfix">
      <Header toilet={toilet} />
      <p className="description">{toilet.description}</p>
    </div>
  );
}

ToiletResult.propTypes = {
  toilet: PropTypes.object.isRequired,
};

export default ToiletResult;
