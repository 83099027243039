import algoliasearch from 'algoliasearch';

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_KEY,
);
const algoliaIndex = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

const SearchAPI = {
  search: (lat, lng) => (
    new Promise((resolve) => {
      algoliaIndex.search({
        query: '',
        aroundLatLng: `${lat}, ${lng}`,
        // aroundRadius: 1000, // 1km
      }).then((toilets) => {
        console.log(lat, lng);
        resolve(toilets);
      });
    })
  ),
};

export default SearchAPI;
